<template>

<div class="schedule">

	<app-content-box :title="day.text" icon="schedule" v-for="day in match.days" :key="day.value" :not-padded="true">

		<div class="schedule-slots">

			<div class="schedule-slots-item" v-tooltip="(slot) ? 'Click to set end time' : 'Click to set start time'" :class="{'is-selected': item.selected, 'is-disabled': item.disabled}" v-for="(item, index) in slots(day.value)" :key="index" v-on:click="onSlotClick(day.value, item.value)">
				{{ item.text }}
			</div>

		</div>

	</app-content-box>

</div>

</template>

<script>

export default {

	props: ['match'],

	data: function() {

		return {
			day: 0,
			slot: 0,
			end: 0
		}

	},

	methods: {

		onSlotClick: function(day, slot) {

			if (this.day === day && this.slot === slot) {

				this.day = false
				this.slot = false

			} else if (this.day && this.slot) {

				this.$emit('select', {
					day: parseInt(this.day),
					time: parseInt(this.slot),
					end: parseInt(slot)
				})

			} else {

				this.day = parseInt(day)
				this.slot = parseInt(slot)

			}

		},

		slots: function(day) {

			var options = []
			//var modifier = (this.$store.getters['user/settings/time']) ? 0 : 12
			var metSlot = false
			var clashSlot = false

			day = parseInt(day)

			this.$_.each(this.match.times, function(text, value) {

				value = parseInt(value)

				var disabled = false
				var clashing = false

				// if (this.$store.getters['user/seats/clash'](parseInt(day), i, true, this.match.seats)) disabled = true

				if (this.day && this.day !== day) disabled = true
				if (this.slot && this.slot > value) disabled = true
				if (clashing) disabled = true
				if (clashSlot && clashSlot < value) disabled = true

				if (this.day === day && this.slot === value) metSlot = true

				if (metSlot && disabled && !clashSlot) clashSlot = value

				options.push({
					value: value,
					text: text,
					disabled: disabled,
					selected: this.day === day && this.slot === value
				})

			}.bind(this))

			return options

		}

	}

}

</script>

<style scoped>

.schedule-slots {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 4px;
}

.schedule-slots-item {
	width: calc(100% / 9);
	font-size: 16px;
	line-height: 40px;
	color: #333;
	font-weight: 400;
	padding: 0px 10px;
	cursor: pointer;
	transition: all 100ms linear;
}

.is-mobile .schedule-slots-item {
	width: 25%;
}

.schedule-slots-item.is-selected,
.schedule-slots-item:hover {
	background-color: #4082d3;
	color: #fff;
	border-radius: 12px;
}

.schedule-slots-item.is-disabled {
	pointer-events: none;
	opacity: 0.5;
}

</style>
